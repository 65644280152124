import React from 'react';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';

function H1Title({
  title,
  variant = 'h3',
  fontSize = '42px',
  fontWeight = 700,
  ...rest
}) {
  return (
    <Typography
      component='span'
      variant={variant}
      sx={{
        fontSize: `${fontSize} !important`,
        fontWeight,
      }}
      {...rest}
    >
      {title}
    </Typography>
  );
}

export default H1Title;
