import React from 'react';
import requireUser from '../lib/requireUser';
import FullWrapper from '../layouts/fullWrapper';
import H1Title from '../components/h1Title';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';

const Home = () => {
  return (
    <FullWrapper headerColor='default' inset='small' headerOffset breadcrumbs={['Welcome']}>
      <Grid container spacing={2}>
        <Grid item xs>
          <H1Title title='Welcome to the Employee Dashboard!' gutterBottom />
        </Grid>
        <Grid item xs={12}>
          <Typography>This is a tool for performing administrative actions. Choose one of the tabs on the sidebar to get started.</Typography>
        </Grid>
      </Grid>
    </FullWrapper>
  )
}

export default requireUser(Home);